<template>
  <div class="checkbox relative">
    <input
      type="checkbox"
      v-model="proxyChecked"
      :value="value"
      :id="id" />
    <label :for="id" :class="{ bold: isBold }">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: {
      type: [Array, Boolean],
      default: false
    },
    value: {
      default: null
    },
    label: String,
    isBold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  },
  data () {
    return {
      id: null
    }
  }, 
  mounted () {
    this.id = this._uid
  },
}
</script>

<style scoped lang="scss">
.checkbox {
  input[type=checkbox] {
    display: none;
  }

  input[type=checkbox] + label{
    cursor: pointer;
    padding-left:25px;
  }

  input[type=checkbox] + label:before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 4px;
    border-radius: 4px;
    border: 1px solid #d7d7d7;
  }

  input[type=checkbox]:checked + label:before{
    content: "\2714";
    font-size: 12px; 
    font-weight: 400; 
    color: #fff;  
    background: #283aef;
    text-align: center; 
    border: 0;  
  }

  label {
    margin-left: 3px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.56px;
    color: #333333;

    &.bold {
      font-weight: 700;
    }
  }
}

@media (max-width: 639px) {
  .checkbox {
    input[type=checkbox] + label {
      padding-left: 17px;
    }
    input[type=checkbox] + label:before {
      width: 15px;
      height: 15px;
      top: 7px;
    }
    label {
      font-size: 12px;
      line-height: 1.2;
    }
  }
}
</style>