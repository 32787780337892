<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="signup-form">
      <div
        class="signup-form-header flex flex-row items-center justify-between"
      >
        <h3 v-if="step == 1">회원가입</h3>
        <h3 v-else-if="step == 2 || step == 3">회원정보입력</h3>
        <h3 v-else-if="step == 4">회원가입완료</h3>
      </div>

      <div class="signup-form-content">
        <p v-if="step == 1" class="content-title">
          반갑습니다! 비유 서비스 이용약관에 동의 해주세요.
        </p>
        <p v-else-if="step == 2" class="content-title">
          회원가입을 위해 아래 정보를 입력해주세요.
        </p>
        <p v-else-if="step == 3" class="content-title">
          이메일 인증을 완료해주세요.
        </p>

        <div class="signup-step-1" v-if="step == 1">
          <Checkbox
            value="agree_all"
            :isBold="true"
            label="모두 동의합니다."
            :checked="agreeAll"
            @change="onAgreeAll"
          />

          <hr class="divider" />

          <div class="agree-item flex flex-row items-center justify-between">
            <Checkbox
              v-model="agrees"
              value="agree_1"
              label="[필수]만 14세 이상입니다."
            />
          </div>

          <div class="agree-item flex flex-row items-center justify-between">
            <Checkbox
              v-model="agrees"
              value="agree_2"
              label="[필수] 비유 서비스 이용약관 동의"
            />
            <img @click="goToTerm" src="@/assets/arrow-right.png" alt="arrow" />
          </div>

          <div class="agree-item flex flex-row items-center justify-between">
            <Checkbox
              v-model="agrees"
              value="agree_3"
              label="[필수] 개인정보 수집 및 이용 동의"
            />
            <img
              @click="goToPrivacy"
              src="@/assets/arrow-right.png"
              alt="arrow"
            />
          </div>

          <div class="agree-item flex flex-row items-center justify-between">
            <Checkbox
              v-model="agrees"
              value="agree_4"
              label="[선택] 마케팅 정보 수신에 대한 동의"
            />
          </div>

          <p>
            만 14세 이상 회원 가입 가능합니다.<br />
            해당 내용은
            <router-link :to="{ name: 'Term' }">이용약관 및 정책</router-link
            >에서도 확인이 가능합니다.
          </p>

          <button
            v-on:click="onGoToStep2"
            class="w-full flex justify-center items-center"
            v-bind:class="{ inactive: !canGoToStep2 }"
          >
            동의하고 진행하기
          </button>
        </div>

        <div class="signup-step-2" v-if="step == 2">
          <div class="signup-input-form flex flex-col">
            <label>이메일</label>
            <input
              v-model="email"
              type="email"
              placeholder="이메일을 입력해주세요"
            />
          </div>

          <div class="signup-input-form flex flex-col">
            <label>이름</label>
            <input
              v-model="name"
              type="text"
              placeholder="이름을 입력해주세요"
            />
          </div>

          <div class="signup-input-form flex flex-col">
            <label>닉네임</label>
            <input
              v-model="nickname"
              type="text"
              placeholder="닉네임을 입력해주세요"
            />
          </div>

          <div class="signup-input-form flex flex-col">
            <label>비밀번호</label>
            <input
              v-model="password"
              type="password"
              placeholder="8자리 이상 영문, 숫자, 특수문자 포함"
            />
          </div>

          <div class="signup-input-form flex flex-col">
            <label>비밀번호 확인</label>
            <input
              v-model="password2"
              type="password"
              placeholder="비밀번호 확인"
            />
          </div>

          <button
            v-on:click="onGoToStep3"
            class="w-full flex justify-center items-center"
            v-bind:class="{ inactive: !canGoToStep3 }"
          >
            다음
          </button>
        </div>

        <div class="signup-step-3" v-if="step == 3">
          <div class="signup-input-form flex flex-col">
            <label>인증코드</label>
            <input
              v-model="code"
              type="text"
              placeholder="인증코드를 입력해주세요"
            />
          </div>

          <button
            v-on:click="onSubmit"
            class="w-full flex justify-center items-center"
            v-bind:class="{ inactive: !canGoToStep4 }"
          >
            회원가입 완료
          </button>
        </div>

        <div class="signup-step-4" v-if="step == 4">
          <div class="flex flex-col items-center">
            <img src="@/assets/signup-complete.png" />
            <p>
              {{ getName }}님! 비유 회원 가입해주셔서 감사합니다.<br />
              지금 바로 비유 서비스를 이용해 주세요.
            </p>
          </div>

          <button
            v-on:click="goToLogin"
            class="w-full flex justify-center items-center"
          >
            서비스 시작하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/components/Checkbox.vue";
import { mapActions, mapGetters } from "vuex";
import services from "@/services";

export default {
  name: "Signup",
  components: { Checkbox },
  data() {
    return {
      step: 1,
      agrees: [],
      email: "",
      nickname: "",
      password: "",
      password2: "",
      name: "",
      code: "",
    };
  },
  computed: {
    agreeAll: function() {
      return this.agrees.length == 4;
    },
    canGoToStep2: function() {
      return this.agrees.filter((v) => v !== "agree_4").length === 3;
    },
    canGoToStep3: function() {
      return (
        this.email.length > 0 &&
        this.nickname.length > 0 &&
        this.name.length > 0 &&
        this.password.length > 7 &&
        this.password2.length > 7 &&
        this.password === this.password2
      );
    },
    canGoToStep4: function() {
      return this.code.length > 0;
    },
    ...mapGetters("user", ["getName"]),
  },
  methods: {
    onAgreeAll: function() {
      if (this.agrees.length > 0) {
        this.agrees = [];
      } else {
        this.agrees = ["agree_1", "agree_2", "agree_3", "agree_4"];
      }
    },
    onGoToStep2: function() {
      if (this.canGoToStep2) {
        this.step = 2;
      }
    },
    async onGoToStep3() {
      if (this.canGoToStep3) {
        try {
          const { status, message } = await this.verify();
          if (status === "success") {
            this.$toast.open("이메일로 인증번호를 발송했습니다");
            this.step = 3;
          } else {
            this.$toast.open({ message, type: "error" });
          }
        } catch (e) {
          const { message } = e.response.data;
          this.$toast.open({ message, type: "error" });
        }
      }
    },
    async verify() {
      const success = await services.verify({ email: this.email });
      return success;
    },
    async verifyCheck() {
      const success = await services.verifyCheck({
        email: this.email,
        verifyNum: this.code,
      });
      return success;
    },
    async onSubmit() {
      if (this.canGoToStep4) {
        const success = await this.verifyCheck();

        if (success) {
          const data = {
            nickname: this.nickname,
            name: this.name,
            email: this.email,
            password: this.password,
            passwordConfirmation: this.password2,
            isAgreeService: this.agrees.includes("agree_2"),
            isAgreePersonal: this.agrees.includes("agree_3"),
            isAgreeMarketing: this.agrees.includes("agree_4"),
          };

          await this.signup(data);
          this.step = 4;
        } else {
          this.$toast.open({
            message: "인증번호를 확인해주세요",
            type: "error",
          });
        }
      }
    },
    goToLogin: function() {
      this.$router.replace("/login");
    },
    ...mapActions("user", ["signup"]),
    goToTerm() {
      this.$router.push({ name: "Term" });
    },
    goToPrivacy() {
      this.$router.push({ name: "Privacy" });
    },
  },
};
</script>

<style scoped lang="scss">
.signup-form {
  margin-top: 225px;
  margin-bottom: 130px;
  border: 1px solid #d7d7d7;
  width: 588px;
  padding-top: 74px;
  padding-bottom: 45px;

  .signup-form-header {
    margin-left: 65px;
    margin-right: 65px;
    padding-bottom: 35px;
    border-bottom: 1px solid #dddee1;

    h3 {
      font-size: 30px;
      font-weight: 700;
      color: #333333;
      letter-spacing: -1.2px;
    }
  }

  .signup-form-content {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 38px;

    .content-title {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.56px;
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 22px;
      color: #eee;
    }
  }

  .signup-step-1 {
    margin-top: 55px;

    .agree-item {
      margin-bottom: 22px;

      img {
        cursor: pointer;
      }
    }

    p {
      font-size: 13px;
      color: #898989;
      letter-spacing: -0.52px;
      font-weight: 500;

      a {
        color: #293bf0;
      }
    }

    button {
      margin-top: 34px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;

      &.inactive {
        background-color: #dfdfdf;
      }
    }
  }

  .signup-step-2 {
    margin-top: 50px;

    .signup-input-form-group {
      span {
        margin-right: 15px;
        margin-left: 15px;
      }
    }

    .signup-input-form {
      margin-bottom: 22px;

      label {
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.56px;
      }

      input {
        color: #333333;
        height: 40px;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.14px;
        padding-left: 9px;
        padding-right: 9px;

        &::placeholder {
          color: #c1c1c1;
        }
      }
    }

    button {
      margin-top: 36px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;

      &.inactive {
        background-color: #dfdfdf;
      }
    }
  }

  .signup-step-3 {
    margin-top: 50px;

    .signup-input-form {
      margin-bottom: 17px;

      label {
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.56px;
      }

      input {
        color: #333333;
        height: 40px;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.14px;
        padding-left: 9px;
        padding-right: 9px;

        &::placeholder {
          color: #c1c1c1;
        }
      }
    }

    button {
      margin-top: 36px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;

      &.inactive {
        background-color: #dfdfdf;
      }
    }
  }

  .signup-step-4 {
    padding-top: 85px;

    p {
      margin-top: 36px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.32;
      letter-spacing: -0.56px;
      text-align: center;
      color: #333;
    }

    button {
      margin-top: 63px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;
    }
  }
}

@media (max-width: 639px) {
  .signup-form {
    margin-top: 100px;
    margin-bottom: 50px;
    border: none;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    .signup-form-header {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 20px;
      h3 {
        font-size: 20px;
      }
    }
    .signup-form-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 25px;
      .content-title {
        font-size: 12px;
      }
    }
    .signup-step-1 {
      margin-top: 30px;
      p {
        font-size: 11px;
      }
    }
    .signup-step-2 {
      .signup-input-form {
        label {
          font-size: 12px;
        }
        input {
          height: 35px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
